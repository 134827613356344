var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo d-flex align-items-center animate__animated animate__fadeInLeft"},[_c('aiot-logo'),_c('span',{staticClass:"brand-text text-white text-center font-medium-5"},[_vm._v(" "+_vm._s(_vm.$t('name'))+" ")])],1),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"lg":"8"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5"},[_c('b-img',{staticStyle:{"height":"660px","width":"780px"},attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Register Image"}})],1)]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5 animate__animated animate__fadeInRight",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"mb-1 mb-3 x-text-bold",attrs:{"title-tag":"h1"}},[_vm._v(" "+_vm._s(_vm.$t('registerTitle'))+" ")]),_c('validation-observer',{ref:"registerForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-register-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.register($event)}}},[_c('b-form-group',{attrs:{"label":_vm.$t('username'),"label-class":"h4 x-text-bold","label-for":"register-username"}},[_c('validation-provider',{attrs:{"name":"Username","vid":"username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"x-clear-input",attrs:{"id":"register-username","name":"register-username","size":"lg","state":errors.length > 0 ? false:null,"placeholder":_vm.$t('placeholderUsername')},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v("{用户名不能为空")]):_vm._e()]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('email'),"label-class":"h4 x-text-bold","label-for":"register-email"}},[_c('validation-provider',{attrs:{"name":"Email","vid":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-email","name":"register-email","size":"lg","state":errors.length > 0 ? false:null,"placeholder":_vm.$t('placeholderEmail')},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=$$v},expression:"userEmail"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v("邮箱不能为空")]):_vm._e()]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"register-password","label":_vm.$t('password'),"label-class":"h4 x-text-bold"}},[_c('validation-provider',{attrs:{"name":"Password","vid":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge x-clear-input",attrs:{"id":"register-password","size":"lg","type":_vm.passwordFieldType,"state":errors.length > 0 ? false:null,"name":"register-password","placeholder":_vm.$t('placeholderPassword')},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v("密码不能为空")]):_vm._e()]}}],null,true)})],1),_c('b-form-group',[_c('b-form-checkbox',{attrs:{"id":"register-privacy-policy","name":"checkbox-1"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_vm._v(" "+_vm._s(_vm.$t('privacy1'))+" "),_c('b-link',[_vm._v(_vm._s(_vm.$t('privacy2')))])],1)],1),_c('b-button',{staticClass:"mt-2",attrs:{"variant":"primary","block":"","type":"submit","disabled":invalid || !_vm.status}},[_vm._v(" "+_vm._s(_vm.$t('register'))+" ")])],1)]}}])}),_c('p',{staticClass:"text-right mt-2"},[_c('span',[_vm._v(_vm._s(_vm.$t('hasAccount')))]),_c('b-link',{attrs:{"to":{name:'auth-login'}}},[_c('span',{staticClass:"font-weight-bolder"},[_vm._v(" "+_vm._s(_vm.$t('login')))])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }